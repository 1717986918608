import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import './earthly.css';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import commitment_img from '../images/commitment.png'
import hero_img from '../images/earthly_banner.png';
import rubric_img from '../images/rubric_mod.png'



const Method = () => (
  <Layout>
    <SEO title="Earthly Method" image={hero_img}/>
    <div style={{marginTop:"30px",marginBottom:"10px"}}>
    <div className="row">
      <div className="col-md-12">
        <p className="h6" style={{color:"#15B770"}}>The 411 on Earthly</p>
      </div>
    </div>
    <br/>
      <div className="row">
          <div className="col-md-12">
            <h2 className="mission_header">
            What is Earthly?</h2>
          </div>
      </div>
      <div className="row">
  <div className="col-md-10 col-sm-12">
          <p className="text-justify mission_page_first_about">Earthly’s mission is to be the 'consumer reports' for everyday products you buy online. 
          Earthly's ratings and labels empower people to buy better 
           and support causes they care about directly through
          their everyday shopping habits. The ratings are data driven, taking into account
          the multitude of attributes that make up each product's impact on health,
          environment and society (as opposed to a single "organic” or “fair trade” tag). </p>
          <p className="text-justify mission_page_first_about">
          Our first product is a free chrome extension that automatically overlays research insights as you shop on Amazon.com, helping you
          buy better everyday. It is powered by technology that blends together good old-fashioned journalism with the latest in data science to bring you the story behind the products you buy. We invite you to take a look at what we're building, and
          talk to us about how we can work together.</p>
        </div>
      </div>

       <div className="row">
          <div className="col-md-12">
            <h2 className="mission_header">
            Who is Earthly intended for?</h2>
          </div>
      </div>
      <div className="row">
  <div className="col-md-10 col-sm-12">
          <p className="text-justify mission_page_first_about">
          Anyone who:<br/>
(1) Shops online and wants to know everything there is to know about a product <br/>
(2) Wants real science, cutting through the marketing fluff <br/>
(3) Intends to consume more consciously but isn’t satisfied with their ability to do so. <br/>
(4) Seeks out cleaner, lower impact products. <br/>
(5) Is curious and passionate about what they put into their bodies, bring into their homes and put out into the environment. <br/>

<br/>Earthly could be particularly useful to:<br/>
(1) Parents of younger kids who need to be vigilant about the chemicals they bring into their homes. <br/>
(2) People prone to allergies, respiratory difficulties, hormonal imbalances <br/>
(3) Vegan, ethical and animal cruelty free consumers<br/>
(4) Environmental and sustainability enthusiasts <br/>
<br/>Curious about how your toothpaste, your shampoo, your deodorant, your sunscreen, your cleaner etc. stack up? Earthly can tell you!</p>
        </div>
      </div>

       <div className="row">
          <div className="col-md-9">
            <h2 className="mission_header">
            What is Earthly's approach to rating products?</h2>
          </div>
      </div>
      <div className="row">
  <div className="col-md-10 col-sm-12">
          <p className="text-justify mission_page_first_about">
            Earthly helps you find products that are better for your body, safe for your home and 
            great for our planet. What's tested to be good for your body doesn't necessarily have to be good 
            for other organisms, and vice versa. We simply arm you with data to help you make the most informed 
              purchase decision.</p>
        <center><img src={rubric_img} style={{width: '350px'}} /></center>

          <p className="text-justify mission_page_first_about">Our approach begins with first principles,
          starting with the ingredients listed. Is the listing complete, or if it contains generic terms
          listed instead of actual compounds (e.g., fragrance, surfactant etc.). We flag products that
          don't pass our sniff test on ingredients with the ingredients transparency label. Next, we
          cross reference the ingredients against <a href="http://www.rbnainfo.com/designated-lists.php">designated lists</a> and databases of chemicals (NIH, EPA etc.) that include
          carcinogens, allergens, environmental toxins, endocrine disruptors, immune disruptors etc. We check if any ingredients
          have material impact on habitats (e.g., palm oil derived ingredients have decimated virgin
            rainforests for the last 30 years). Lastly, we look at the company that makes these products,
          what their ethics are, where they stand on animal cruelty, fair trade and using non-GMO
          ingredients etc. And voila, a more holistic picture emerges about the story behind the product
          and the impact that it will have on the consumer and the environment
      </p>
        </div>
      </div>

       <div className="row">
          <div className="col-md-12">
            <h2 className="mission_header">
            How do I interpret Earthly labels?</h2>
          </div>
      </div>
      <div className="row">
  <div className="col-md-10 col-sm-12">
          <p className="text-justify mission_page_first_about">Products with Earthly label overlays 
          matched information that we deemed important and useful to help make better purchase decisions (see Earthly's <a href="/mission#sus">sustainability criteria</a>).
          Labels consist of 3 components: <br/>(1) The leaf rating is indicative of how the labelled product ranks in comparison to all other labeled products within the same top level category
<br/>(2) Flags that indicate what we've found about the product from our research (e.g., Contains toxins, Uses palm oil etc.) <br/>
(3) Any certifications that the product has received from accredited certifying agencies.

          </p>
        </div>
      </div>

       <div className="row">
          <div className="col-md-12">
            <h2 className="mission_header">
            Why doesn't every product have a label?</h2>
          </div>
      </div>
      <div className="row">
  <div className="col-md-10 col-sm-12">
          <p className="text-justify mission_page_first_about">Our north star vision is to have detailed information on every product in the universe. We'll be first to acknowledge that there
          are a lot of products out there! We've started with a small set of products in a handful of categories, scrutinizing them along a handful of dimensions.
          We're only getting started validating our hypothesis - that providing users with better information than what's presented to them on e-commerce product cards or packaging, can have lasting impact on people's health and that of the planet. </p>
        </div>
      </div>

       <div className="row">
          <div className="col-md-12">
            <h2 className="mission_header">
            Does Earthly make money from labeled products?</h2>
          </div>
      </div>
      <div className="row">
  <div className="col-md-10 col-sm-12">
          <p className="text-justify">No. We know that economic incentives and doing the right
          thing aren't always aligned. We aren't affiliated with any of the products that we label to make it clear that we are all about providing
          value to the conscious shopper. </p>
        </div>
      </div>


    </div>

  </Layout>
)

export default Method
